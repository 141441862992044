header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 999;
  transition: all 0.4s;

  .container_big {
    display: flex;
    justify-content: space-between;
    align-items:center;

    @include break(small-screen) {
      padding-top: 22px;
      padding-bottom: 22px;
    }
    @include break(tablet) {
      padding-top: 15px;
      padding-bottom: 15px;
    }
  }

  .logo {
    width: 115px;
    height: auto;
    position: relative;
    margin-top: 24px;
    margin-bottom: 24px;
    transition: all 0.4s;
    @include break(tablet) {
      margin-top: 5px;
      margin-bottom: 0px;
      width: 90px;
    }
    svg,img {
      width: 100%;
      height: auto;
      transition: all 0.4s;
    }
    .logo-color {
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }
  }

  .menu-nav-container {
    width: 100%;
  }

  .nav {
    display: flex;
    width: 100%;
    flex-shrink: 1;
    .menu {
      display: flex;
      width: 100%;
      align-items: center;
      justify-content: flex-end;
      li {
        margin-left: 5%;
      }
      a {
        text-decoration: none;
        color: $white;
        font-size: 16px;
        @include font-medium;
        transition: all 0.4s;
        position: relative;
        &:after {
          pointer-events: none;
          content: " ";
          position: absolute;
          top: 125%;
          left: 0;
          right: 0;
          height: 6px;
          opacity: 0;
          transition: all 0.2s;
          background-color: $white;
          display: block;
        }

        &:hover:after {
          height: 2px;
          opacity: 1;
        }
      }
    }
    @include break(tablet) {
      display: none;
    }
  }


  .btn {
    @include break(tablet) {
      width: 120px;
    }
  }



  &.small {
    background-color: $white;
    border-bottom: 1px solid #EFEEED;
    @include break(tablet) {
      .container_big {
        padding-top: 5px;
        padding-bottom: 5px;
      }
    }
    .logo {
      margin-top: 16px;
      margin-bottom: 16px;
      @include break(tablet) {
        margin-top: 5px;
        margin-bottom: 0px;
      }
      .logo-white {
        opacity: 0;
        width: 80px;
        @include break(tablet) {
          width: 65px;
        }
      }
      .logo-color {
        opacity: 1;
        width: 80px;
        @include break(tablet) {
          width: 65px;
        }
      }
    }
    .nav .menu a {
      color: $dark;
      &:after {
        background-color: $ocre;
      }
      &:hover {
        color: $ocre;
      }
    }
  }

  &.no-border {
    border-bottom: 1px solid rgba(#EFEEED, 0);
  }

  // Ajout pour la page template default
  body.page-template-default & {
    background-color: inherit;
    border-bottom: inherit;
    opacity: 1;

    .container_big {
      padding-top: 5px;
      padding-bottom: 5px;

      svg.logo-color {
        opacity: 1;
      }
    }

    .logo {
      margin-top: 16px;
      margin-bottom: 16px;

      &.logo-color {
        opacity: 1;
      }
    }

    .nav .menu a {
      opacity: 0;
    }
  }
}
