@import 'partials/_mixin';
@import 'partials/_function';

@import "./base/variable";
@import "./base/wordpress";
@import './base/breakpoint';
@import "./base/reset.scss";
@import './base/normalize';
@import './base/easing';
@import './base/fonts';
@import './base/layout';

@import './common/header';
@import './common/footer';
@import "./common/general";
@import "./common/anim-class";

@import './pages/home';
@import './pages/legal';
@import './pages/bonnepratiques';
@import './pages/defi';
@import './pages/quatrecentquatre';
