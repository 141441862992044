.oxo-view.template-home {

  .sec1 {
    position: relative;
    height: 100vh;
    width: 100%;
    overflow: hidden;

    video {
      object-fit: cover;
      width: 100vw;
      height: 100vh;
      top: 0;
      left: 0;
      @include absolute-center(center);
    }

    .container {
      @include absolute-center(center);
      z-index: 9;
      color: $white;
      @include break(mobile) {
        top: 40%;
      }
    }

    .play {
      position: absolute;
      right: 15%;
      bottom: 15%;
      @include break(mobile) {
        width: 84px;
        height: 84px;

        svg {
          width: 26px;
        }
      }
    }
  }
  .popupHero {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    display: none;
    align-items: center;
    justify-content: center;
    .close_popup {
      position: absolute;
      top: 30px;
      right: 50px;
      cursor: pointer;
      z-index: 2;
    }
    .player {
      width: 100%;
      position: relative;
      padding-bottom:56.25%;
      iframe, video {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }



  .sec2 {
    padding-top: 160px;
    padding-bottom: 160px;
    background-image: url(../img/sec2_bg.png);
    background-repeat: no-repeat;
    background-size: 220%;
    background-position-y: center;
    background-position-x: -5%;
    transition: background-position-y 0.6s;
    @include break(tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }
    h2 {
      max-width: 700px;
    }
    .txt {
      width: 65%;
      margin-left: 35%;
      padding-top: 60px;
      @include break(tablet) {
        margin-left: 0%;
        width: 100%;
      }
    }
  }


  .sec3 {
    padding-top: 160px;
    padding-bottom: 160px;
    @include break(tablet) {
      padding-top: 20px;
      padding-bottom: 80px;
    }

    .img_wrap {
      width: 40%;
      .sticky_img {
        position: sticky;
        top: 25vh;
        left: 0;
        overflow: hidden;
        .img {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          transform: translateY(101%);
          &:first-of-type {
            position: relative;
            transform: translateY(0%);
          }
          video {
            width: 100%;
          }
        }
      }
      @include break(tablet) {
        display: none;
      }
    }
    .content {
      width: 40%;
      @include break(tablet) {
        width: 100%;
      }
      h3 {
        margin-bottom: 20px;
        @include break(tablet) {
          margin-top: 20px;
        }
      }
    }
    .content_box {
      height: 70vh;
      padding-top: 100px;
      video {
        width: 100%;
      }
      @include break(tablet) {
        height: auto;
        padding-top: 60px;
      }
    }

    .container_cta {
      align-items: center;
      padding-top: 200px;
      @include break(tablet) {
        padding-top: 100px;
        flex-direction: column;
        align-items: flex-end;
      }
      a.round {
        margin-right: 10%;
        @include break(tablet) {
          margin-right: 0%;
        }
      }
    }
  }

  .sec4 {
    padding-top: 160px;
    padding-bottom: 160px;
    @include break(tablet) {
      padding-top: 115px;
      padding-bottom: 80px;
    }
    .producteur {
      display: flex;
      justify-content: space-between;
      padding-bottom: 100px;
      align-items: center;
      &:nth-of-type(odd) {
        flex-direction: row-reverse;
        @include break(tablet) {
          flex-direction: column-reverse;
        }
      }
      &:last-of-type {
        padding-bottom: 0px;
      }
      @include break(tablet) {
        flex-direction: column-reverse;
      }

      .content {
        width: 40%;
        position: relative;
        z-index: 1;
        @include break(tablet) {
          margin-bottom: 50px;
          width: 100%;
        }
        .quote_icon {
          width: 150px;
          @include break(tablet) {
            width: 100px;
          }
          svg {
            width: 150px;
            position: absolute;
            top:0;
            left: 0;
            z-index: -1;
            transform: translate(-40%,-40%);
            @include break(tablet) {
              width: 100px;
              transform: translate(0%,-40%);
            }
          }
        }
        blockquote {
          font-size: 1.3em;
          max-width: 515px;
          margin-bottom: 35px;
          margin-right: 50px;
          @include break(tablet) {
            font-size: 1.3em;
          }
        }

        .name {
          border-left: 6px solid $ocre;
          padding-left: 12px;
          h6, p {
            line-height: 1em;
          }
          h6 {
            padding-bottom: 5px;
          }
        }
      }

      .img {
        width: 60%;
        position: relative;
        margin-left: -6%;
        @include break(tablet) {
          width: 100%;
          margin-left: 0%;
        }
        .play {
          @include absolute-center(center);
          @include break(tablet) {
            width: 85px;
            height: 85px;
            svg {
              width: 27px;
              height: 26px;
              margin-left: 3px;
            }
          }
        }
        .anime_img {
          width: 100%;
          height: auto;
          @include break(tablet) {
            width: 260px;
            height: 260px;
            margin-left: auto;
            margin-right: auto;
            display: block;
          }
        }
        clipPath {
          transform-origin: 50% 50%;
          transition: all 0.4s;
          margin-left: auto;
          margin-right: auto;
        }
        // &:hover clipPath {
        //   transform: scale(1.2);
        // }
      }
    }
  }

  .popupVid {
    position: fixed;
    z-index: 99999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $dark;
    display: none;
    align-items: center;
    justify-content: center;
    .close_popup {
      position: absolute;
      top: 30px;
      right: 50px;
      cursor: pointer;
    }
    .player {
      width: 75%;
      position: relative;
      padding-bottom:56.25%;
      iframe {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

  .bonnespratiques {
    padding-top: 160px;
    padding-bottom: 160px;
    @include break(tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .flex {
      @include break(tablet) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    h2 {
      max-width: 755px;
      padding-bottom: 25px;
    }

    .txt {
      width: 80%;
      padding-bottom: 80px;
      @include break(tablet) {
        width: 100%;
      }
    }

    .card {
      width: 340px;
      height: 515px;
      background-position: center;
      background-size: cover;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transform-style: preserve-3d;
      @include break(tablet) {
        width: 100%;
        height: 135vw;
        max-width: 340px;
        max-height: 515px;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .content {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 3;
        padding: 55px 35px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        transform: translateY(65px);
        pointer-events: none;
        transform-style: preserve-3d;
        transition: all 0.3s 0.2s;
        color: $white;
        @include break(tablet) {
          transform: translateY(0px);
        }
      }
      p {
        font-size: 14px;
        @include font-bold;
        text-transform: uppercase;
      }
      .cta {
        padding-top: 30px;
        font-size: 16px;
        @include font-bold;
        text-transform: uppercase;
        color: $white;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.3s 0.2s;
        @include break(tablet) {
          opacity: 1;
        }
        svg {
          margin-left: 12px;
          width: 23px;
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(20px);
        backface-visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s;
        transform-style: preserve-3d;
      }

      &:hover {
        .overlay {
          transform: translateY(0%);
          opacity: 1;
        }
        .content {
          transform: translateY(0px);
        }
        .cta {
          opacity: 1;
        }
      }

    }
  }

  .sec5 {
    padding-top: 160px;
    padding-bottom: 160px;
    @include break(tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .flex {
      @include break(tablet) {
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }

    h2 {
      max-width: 755px;
      padding-bottom: 25px;
    }

    .txt {
      width: 80%;
      padding-bottom: 80px;
      @include break(tablet) {
        width: 100%;
      }
    }

    .card {
      width: 340px;
      height: 515px;
      background-position: center;
      background-size: cover;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      transform-style: preserve-3d;
      @include break(tablet) {
        width: 100%;
        height: 135vw;
        max-width: 340px;
        max-height: 515px;
        margin-bottom: 20px;
        &:last-of-type {
          margin-bottom: 0;
        }
      }
      .content {
        height: 100%;
        width: 100%;
        position: relative;
        z-index: 3;
        padding: 55px 35px;
        display: flex;
        justify-content: flex-end;
        flex-direction: column;
        transform: translateY(65px);
        pointer-events: none;
        transform-style: preserve-3d;
        transition: all 0.3s 0.2s;
        color: $white;
        @include break(tablet) {
          transform: translateY(0px);
        }
      }
      p {
        font-size: 14px;
        @include font-bold;
        text-transform: uppercase;
      }
      .cta {
        padding-top: 30px;
        font-size: 16px;
        @include font-bold;
        text-transform: uppercase;
        color: $white;
        display: flex;
        align-items: center;
        opacity: 0;
        transition: all 0.3s 0.2s;
        @include break(tablet) {
          opacity: 1;
        }
        svg {
          margin-left: 12px;
          width: 23px;
        }
      }
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        backdrop-filter: blur(20px);
        backface-visibility: hidden;
        opacity: 0;
        pointer-events: none;
        transition: all 0.4s;
        transform-style: preserve-3d;
      }

      &:hover {
        .overlay {
          transform: translateY(0%);
          opacity: 1;
        }
        .content {
          transform: translateY(0px);
        }
        .cta {
          opacity: 1;
        }
      }

    }
  }

  .sec_femmesagriculture {
    padding-top: 160px;
    padding-bottom: 160px;
    @include break(tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .flex {
      @include break(tablet) {
        flex-direction: column;
      }
    }

    .title {
      h2 {
        max-width: 800px;
        padding-bottom: 25px;
      }

      .txt {
        width: 80%;
        padding-bottom: 80px;
      }
    }

    .femmenumber {
      font-size: 4em;
      line-height: 1em;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      color: #EFEEED;
      text-decoration: none;
      transition: all 0.4s;
      @include break(tablet) {
        font-size: 1.55em;
        width: 33%;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #EFEEED;
      }
      &.active {
        color: $green;
        border-color: $green;
        pointer-events: none;
      }
    }

    .tablet {
      display: none;
      width: 101vw;
      margin-left: -5vw;
      margin-bottom: 50px;
      @include break(tablet) {
        display: flex;
      }
    }

    .sticky_wrap {
      width: 325px;
      @include break(tablet) {
        display:none;
      }
      .sticky {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .content {
      border-left: 1px solid #EFEEED;
      padding-left: 12%;
      @include break(tablet) {
        padding-left: 0;
        position: relative;
        border-left: none;
      }

      .how_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        @include break(tablet) {
          min-height: 0;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          &:first-of-type {
            position: relative;
            opacity: 1;
          }
        }
        .txt {
          font-size: 1.2em;
          line-height: 1.4em;

          strong {
            color: $ocre;
            font-weight: 400;
          }
        }
        .how {
          border: 2px solid #D18F36;
          border-radius: 8px;
          padding: 38px 32px;
          color: $ocre;
          margin-top: 45px;
          position: relative;
          .arrow {
            width: 22px;
            height: 13px;
            border-bottom: 5px solid #ffffff;
            position: absolute;
            top: -12px;
            left: 55px;
            svg {
              margin-left: -2px;
              width: 26px;
              height: 13px;
              transform: translateY(-11px);
              @include break(mobile) {
                transform: translateY(-9px);
              }
            }
          }
          p {
            @include font-medium;
            font-size: 0.9em;
            line-height: 1.4em;
          }
          h6 {
            text-transform: uppercase;
            padding-bottom: 10px;
          }
        }

      }
    }

    .txt_img {
      padding-top: 160px;
      overflow: visible;
      @include break(tablet) {
        padding-top: 80px;
        overflow: hidden;
      }

      .flex {
        width: 110%;
        justify-content: space-between;
        margin-right: -10%;
        @include break(tablet) {
          margin-right: 0%;
          width: 100%;
          &.flex2 {
            flex-direction: row;
          }
        }
      }

      .txt {
        font-size: 1.6em;
        line-height: 1.6em;
        width: 80%;
        max-width: 740px;
        @include break(tablet) {
          width: 100%;
          font-size: 1.3em;
        }
        a, strong {
          color: $ocre;
          text-decoration: none;
          font-weight: 400;
        }
      }

      .left, .right {
        width: 50%;
        padding-top: 110px;
        @include break(tablet) {
          padding-top: 80px;
        }
      }

      .img1 {
        width: 310px;
        height: auto;
        @include break(tablet) {
          margin-left: auto;
          width: 50%;
          padding-top: 45px;
        }
      }

      .img2 {
        width: 450px;
        height: auto;
        margin-left: 5%;
        @include break(tablet) {
          width: 100%;
          margin-left: -11%;
        }
      }

      .img3 {
        width: 400px;
        height: auto;
        margin-left: 10%;
        margin-top: 200px;
        @include break(tablet) {
          width: 90%;
          margin-top: 55%;
          margin-left: 0%;
        }
      }

    }
  }

  .sec6 {
    padding-top: 160px;
    padding-bottom: 160px;
    @include break(tablet) {
      padding-top: 80px;
      padding-bottom: 80px;
    }

    .flex {
      @include break(tablet) {
        flex-direction: column;
      }
    }

    .title {
      h2 {
        max-width: 800px;
        padding-bottom: 25px;
      }

      .txt {
        width: 80%;
        padding-bottom: 80px;
      }
    }

    .number {
      font-size: 4em;
      line-height: 1em;
      padding-top: 12.5px;
      padding-bottom: 12.5px;
      color: #EFEEED;
      text-decoration: none;
      transition: all 0.4s;
      @include break(tablet) {
        font-size: 1.55em;
        width: 33%;
        text-align: center;
        padding-bottom: 10px;
        border-bottom: 1px solid #EFEEED;
      }
      &.active {
        color: $green;
        border-color: $green;
        pointer-events: none;
      }
    }

    .tablet {
      display: none;
      width: 101vw;
      margin-left: -5vw;
      margin-bottom: 50px;
      @include break(tablet) {
        display: flex;
      }
    }

    .sticky_wrap {
      width: 325px;
      @include break(tablet) {
        display:none;
      }
      .sticky {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: sticky;
        top: 0;
        left: 0;
        width: 100%;
      }
    }

    .content {
      border-left: 1px solid #EFEEED;
      padding-left: 12%;
      @include break(tablet) {
        padding-left: 0;
        position: relative;
        border-left: none;
      }

      .how_box {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 100vh;
        @include break(tablet) {
          min-height: 0;
          position: absolute;
          top: 0;
          left: 0;
          opacity: 0;
          &:first-of-type {
            position: relative;
            opacity: 1;
          }
        }
        .txt {
          font-size: 1.2em;
          line-height: 1.4em;

          strong {
            color: $ocre;
            font-weight: 400;
          }
        }
        .how {
          border: 2px solid #D18F36;
          border-radius: 8px;
          padding: 38px 32px;
          color: $ocre;
          margin-top: 45px;
          position: relative;
          .arrow {
            width: 22px;
            height: 13px;
            border-bottom: 5px solid #ffffff;
            position: absolute;
            top: -12px;
            left: 55px;
            svg {
              margin-left: -2px;
              width: 26px;
              height: 13px;
              transform: translateY(-11px);
              @include break(mobile) {
                transform: translateY(-9px);
              }
            }
          }
          p {
            @include font-medium;
            font-size: 0.9em;
            line-height: 1.4em;
          }
          h6 {
            text-transform: uppercase;
            padding-bottom: 10px;
          }
        }

      }
    }

    .txt_img {
      padding-top: 160px;
      overflow: visible;
      @include break(tablet) {
        padding-top: 80px;
        overflow: hidden;
      }

      .flex {
        width: 110%;
        justify-content: space-between;
        margin-right: -10%;
        @include break(tablet) {
          margin-right: 0%;
          width: 100%;
          &.flex2 {
            flex-direction: row;
          }
        }
      }

      .txt {
        font-size: 1.6em;
        line-height: 1.6em;
        width: 80%;
        max-width: 740px;
        @include break(tablet) {
          width: 100%;
          font-size: 1.3em;
        }
        a, strong {
          color: $ocre;
          text-decoration: none;
          font-weight: 400;
        }
      }

      .left, .right {
        width: 50%;
        padding-top: 110px;
        @include break(tablet) {
          padding-top: 80px;
        }
      }

      .img1 {
        width: 310px;
        height: auto;
        @include break(tablet) {
          margin-left: auto;
          width: 50%;
          padding-top: 45px;
        }
      }

      .img2 {
        width: 450px;
        height: auto;
        margin-left: 5%;
        @include break(tablet) {
          width: 100%;
          margin-left: -11%;
        }
      }

      .img3 {
        width: 400px;
        height: auto;
        margin-left: 10%;
        margin-top: 200px;
        @include break(tablet) {
          width: 90%;
          margin-top: 55%;
          margin-left: 0%;
        }
      }

    }
  }

  .sec7 {
    .flex_content {
      display: flex;
      align-items: center;
      width: 100%;
      @include break(tablet) {
        flex-direction: column;
        padding-bottom: 80px;
      }
    }
    .img {
      width: 40%;
      overflow: hidden;
      @include break(tablet) {
        width: 100%;
      }
      img {
        width: 100%;
        height: auto;
      }
    }
    .content {
      padding-left: 10%;
      padding-right: 10%;
      width: 60%;
      position: relative;
      @include break(tablet) {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        padding-top: 60px;
      }
      .txt {
        padding-top: 20px;
        max-width: 500px;
        p {
          line-height: 1.6em;
        }
      }

      .btn_wrap {
        display: flex;
        padding-top: 80px;
        @include break(tablet) {
          width: 100%;
          justify-content: center;
        }
        .share .bg {
          background-color: $ocre;
        }
        .form_btn {
          margin-left: 50px;
          .bg {
            background-color: $green;
          }
        }
      }
    }
  }


  .form_wrap {
    display: none;
    z-index: 99999;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .bg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: rgba($dark, 0.3);
    }
    .form_container {
      position: absolute;
      padding: 60px 80px;
      top: 0;
      right: 0;
      bottom: 0;
      background-color: #FAFAFA;
      width: 692px;
      height: 100%;
      backdrop-filter: blur(34px);
      border-radius: 28px 0px 0px 28px;
      overflow-x: hidden;
      overflow-y: scroll;
      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }
      @include break(tablet) {
        width: 100%;
        padding: 32px;
        border-radius: 0px;
      }

      .close {
        position: absolute;
        top: 20px;
        right: 40px;
        cursor: pointer;
      }

      .txt {
        padding-top: 20px;
        padding-bottom: 40px;
      }

      form {
        p {
          padding-bottom: 16px;
        }
        input {
          margin-top: 8px;
          margin-bottom: 8px;
          font-size: 16px;
          width: 100%;
          border-radius: 8px;
          padding: 20px 18px;
          border: 1px solid  #BFBEBD;
          outline: none!important;
          background-color: $white;
          transition: all 0.4s;
          color: #BFBEBD;

          &:focus {
            border: 1px solid $dark;
            color: $dark;;
          }

          &[type='checkbox'] {
            width: auto;
            margin-right: 8px;
          }
          &[type='submit'] {
            width: auto;
            margin-right: 8px;
            width: 160px;
            height: 160px;
            transform-origin: 50% 50%;
            transform-style: preserve-3d;
            cursor: pointer;
            background-color: $ocre;
            border-radius: 50%;
            border: none;
            color: $white;
            text-transform: uppercase;
            @include font-bold;
            transition: all 0.5s;
            @include break(tablet) {
              width: 140px;
              height: 140px;
            }
            &:hover {
              transform: scale(1.25);
              font-size: 13px;
            }
          }
        }
        span.wpcf7-form-control.wpcf7-acceptance.optional label{
          font-size: 14px;
          @include font-regular;
        }
        .wpcf7-list-item {
          margin: 0;
        }
        label {
          font-size: 16px;
          line-height: 1em;
          @include font-medium;
        }
      }

    }
    .wpcf7 form.sent .wpcf7-response-output {
      border: none;
      position: absolute;
      top: -40px;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 20px 65px;
      @include font-bold;
      font-size: 2em;
      line-height: 1.2em;
      background-color: #FAFAFA;
    }

    .share_list {
      padding-top: 30px;
      a {
        margin-right: 10px;
      }
    }
  }
}
