.bp_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: $dark;
  z-index: 99999;
  color: $white;
  overflow-y: hidden;
  overflow-x: scroll;
  display: none;
  @media only screen and (max-height: 820px) and (min-width: $break-tablet) {
    font-size: 16px;
  }
  @include break(tablet) {
    overflow-y: scroll;
    overflow-x: hidden;
  }

  // &.bp_popup0 {
  //   display: block;
  // }

  .top {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    border-bottom: 1px solid $white;
    display: flex;
    align-items: center;
    height: 80px;
    z-index: 10;
    background-color: $dark;
    @include break(tablet) {
      position: fixed;
      height: 60px;
      padding-left: 5%;
    }
    .back {
      position: sticky;
      top: 0;
      left: 5vw;
      line-height: 1em;
      display: flex;
      align-items: center;
      cursor: pointer;
      @include break(tablet) {
        position: relative;
      }

      svg {
        width: 20px;
        pointer-events: none;
      }
      span {
        pointer-events: none;
        margin-left: 10px;
        padding-top: 4px;
        display: block;
        font-size: 14px;
        @include font-medium;
        text-transform: uppercase;
      }
    }
  }

  .bp_inner {
    z-index: 2;
    position: relative;
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    width: max-content;
    >div {
      flex-shrink: 0;
    }
    @include break(tablet) {
      flex-direction: column;
      width: 100%;
    }
  }

  .view1 {
    width: 190vw;
    position: relative;
    height: 100vh;
    padding-top: 80px;
    @include break(tablet) {
      padding-top: 60px;
      width: 100%;
      height: auto;
      margin-bottom: 50px;
    }
    .img_bg {
      width: 90vw;
      height: 100vh;
      background-position: center;
      background-size: cover;
      @include break(tablet) {
        width: 100%;
        height: 80vh;
      }
    }
    .icon {
      width: 132px;
      height: 132px;
      background-color: $ocre;
      border-radius: 50%;
      position: absolute;
      left: 90vw;
      top: 180px;
      transform: translateX(-50%);
      svg {
        width: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%,-50%);
        &.arrow {
          .anime_icon {
            animation: icon 2s linear infinite;
          }
        }
        &.txt {
          animation: rotate 10s linear infinite;
        }
      }

      @include break(tablet) {
        width: 100px;
        height: 100px;
        left: auto;
        right: 5%;
        top: auto;
        bottom: 0;
        transform: translateY(50%);
      }
    }
    .sticky_title {
      width: 100%;
      position: absolute;
      bottom: 120px;
      left: 10vw;
      @include break(tablet) {
        left: 5%;
        bottom: 74px;
      }
      h2 {
        max-width: 880px;
        font-size: 5em;
        @include font-bold;
        position: sticky;
        left: 10vw;
        top: 0;
        @include break(tablet) {
          left: 5%;
          width: 80%;
          font-size: 2.666em;
        }
        @media only screen and (max-width: 360px) {
          font-size: 2.1em;
        }
      }
    }
  }

  .view2 {
    width: auto;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 80px;
    >div {
      flex-shrink: 0;
    }
    @include break(tablet) {
      flex-direction: column;
    }
    .txt_1 {
      padding-top: 80px;
      width: 400px;
      @include break(tablet) {
        padding-top: 0px;
        width: 100%;
        padding-left: 15%;
        padding-right: 5%;
        padding-bottom: 80px;
      }
    }

    &.mod1 {
      .img_1 {
        display: flex;
        align-items: flex-end;
        img {
          width: 320px;
          height: auto;
          @include break(tablet) {
            width: 40%;
          }
        }
      }
      .img-txt {
        padding-left: 115px;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding-right: 0vw;
        @include break(tablet) {
          padding-top: 55px;
          padding-left: 0px;
          width: 100%;
        }
        .img_2 {
          flex-shrink: 0;
          width: 640px;
          height: auto;
          @media only screen and (max-height: 820px) and (min-width: $break-tablet) {
            width: 460px;
          }
          @include break(tablet) {
            padding-top: 55px;
            padding-left: 0px;
            padding-bottom: 80px;
            width: 76%;
            margin-left: auto;
          }
        }
        .txt_2 {
          flex-shrink: 0;
          width: 620px;
          margin-left: 20vw;
          display: flex;
          height: 50%;
          align-items: center;
          @include break(tablet) {
            padding-top: 0px;
            width: 100%;
            margin-left: 0;
            padding-right: 20%;
            padding-left: 5%;
            height: auto;
            padding-bottom: 120px;
          }
        }
      }
    }

    &.mod2 {
      .img_1 {
        display: flex;
        align-items: flex-end;
        img {
          margin-left: -20%;
          width: 570px;
          height: auto;
          @media only screen and (max-height: 820px) and (min-width: $break-tablet) {
            width: 470px;
          }
          @include break(tablet) {
            width: 76%;
            margin-left: auto;
          }
        }
      }
      .img-txt {
        margin-left: -12%;
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        padding-right: 0vw;
        @include break(tablet) {
          margin-left: 0%;
          padding-top: 55px;
          padding-left: 0px;
          width: 100%;
        }
        .img {
          display: flex;
          @include break(tablet) {
            padding-bottom: 80px;
          }
          .img_2 {
            flex-shrink: 0;
            width: 480px;
            height: auto;
            @media only screen and (max-height: 820px) and (min-width: $break-tablet) {
              width: 380px;
            }
            @include break(tablet) {
              width: 70%;
            }
          }
          .img_3 {
            flex-shrink: 0;
            width: 240px;
            height: auto;
            padding-top: 9vw;
            margin-left: -6vw;
            @media only screen and (max-height: 820px) and (min-width: $break-tablet) {
              width: 140px;
            }
            @include break(tablet) {
              width: 40%;
              padding-top: 15vw;
              margin-left: -15%;
              margin-right: 5%;
            }
          }
        }
        .txt_2 {
          flex-shrink: 0;
          width: 620px;
          margin-left: 20vw;
          display: flex;
          height: 50%;
          align-items: center;
          @include break(tablet) {
            padding-top: 0px;
            width: 100%;
            margin-left: 0;
            padding-right: 20%;
            padding-left: 5%;
            height: auto;
            padding-bottom: 120px;
          }
        }
      }
    }

  }

  .view3 {
    padding-left: 180px;
    height: 100vh;
    display: flex;
    flex-wrap: nowrap;
    padding-top: 80px;
    >div {
      flex-shrink: 0;
    }
    @include break(tablet) {
      padding-left: 0px;
      height: auto;
      width: 100%;
      flex-direction: column;
    }
    &.mod2 {
      padding-left: 0px;
    }

    .img_last {
      height: 100%;
      @include break(tablet) {
        height: auto;
        width: 100%;
      }
      img {
        height: 100%;
        width: auto;
        @include break(tablet) {
          height: auto;
          width: 100%;
        }
      }
    }

    .txt {
      padding-top: 80px;
      padding-left: 160px;
      padding-right: 300px;
      @include break(tablet) {
        width: 100%;
        padding-left: 5%;
        padding-right: 20%;
      }
      h3 {
        width: 550px;
        padding-bottom: 20vh;
        @media only screen and (max-height: 820px) and (min-width: $break-tablet) {
          padding-bottom: 10vh;
        }
        @include break(tablet) {
          width: 100%;
          padding-bottom: 80px;
        }
      }
      .share.round {
        margin-left: auto;
        margin-right: -30px;
      }
    }
  }

  .nxt {
    position: relative;
    z-index: 15;
    cursor: pointer;
    width: 45vw;
    background-position: center;
    background-size: cover;
    padding: 80px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    @include break(tablet) {
      width: 100%;
      height: 70vh;
      margin-top: 80px;
      padding: 60px 5%;
    }
    h6 {
      font-size: 12px;
      padding-bottom: 20px;
      text-transform: uppercase;
    }
  }

}
