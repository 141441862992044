
.anime-txt, .anime-txt_round, .anime-txt_round-center, header, .anime-comment_txt, .anime-horz_txt, .anime-horz_img-up, .anime-horz_img-down, .anime-horz_round {
  opacity: 0;
}

.anime-horz_txt, .anime-horz_img-up, .anime-horz_img-down, .anime-horz_round {
  @include break(tablet) {
    opacity: 1;
  }
}

.anime-fact_txt {
  opacity: 0.5;
}

.anime-txt_round, .anime-txt_round-center {
  transform-origin: center;
}


@keyframes rotate {
  0%{
    transform: translate(-50%,-50%) rotate(0deg);
  }
  100%{
    transform: translate(-50%,-50%) rotate(359deg);
  }
}

@keyframes icon {
  0%{
    transform: translateY(0%);
    opacity: 0;
  }
  30%{
    transform: translateY(0%);
    opacity: 1;
  }
  60%{
    transform: translateY(0%);
    opacity: 1;
  }
  100%{
    transform: translateY(35%);
    opacity: 0;
  }
}
