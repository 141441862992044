.legal-notices {
  padding-top: 160px;
  padding-bottom: 160px;

  .container {
    width: 90%;
    max-width: 1055px;
    margin-left: auto;
    margin-right: auto;
    min-height:calc(100vh - 185px);
    text-align: left;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  h2 {
    margin-top: 60px;
    @include font-bold;
    font-size: 2.6em;
    line-height: 1.2em;
    @include break(tablet) {
      font-size: 2em;
    }
  }
}
