footer {
  background-color: $dark;
  color: $white;
  padding-top: 90px;
  padding-bottom: 90px;

  .flex {
    @include break(mobile) {
      flex-direction: column;
      align-items: center;
    }
  }

  .logo_wrap {
    display: flex;
    @include break(mobile) {
      padding-bottom: 85px;
    }
    .logo1 {
      width: 105px;
      margin-right: 32px;
    }
    .logo2 {
      width: 73px;
    }
  }

  .contact {
    h6 {
      color: #5A5753;
      font-size: 16px;
      padding-bottom: 10px;
      line-height: 1em;
    }
    @include break(mobile) {
      padding-bottom: 85px;
      text-align: center;
    }

    &__copyrights {
      a {
        text-decoration: underline;
      }
    }
  }

  .socials {
    display: flex;
    a {
      margin-left: 16px;
      transition: all 0.3s;
      svg {
        overflow: visible;
        circle {
          transform-origin: center;
          transition: all 0.3s;
        }
      }
      &:hover circle {
        transform: scale(1.15);
      }
    }
  }

}
