@font-face {
    font-family: 'DIN';
    src: url('../fonts/DINBold.eot');
    src: url('../fonts/DINBold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DINBold.woff2') format('woff2'),
        url('../fonts/DINBold.woff') format('woff'),
        url('../fonts/DINBold.ttf') format('truetype'),
        url('../fonts/DINBold.svg#DINBold') format('svg');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN';
    src: url('../fonts/DIN-Black.eot');
    src: url('../fonts/DIN-Black.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DIN-Black.woff2') format('woff2'),
        url('../fonts/DIN-Black.woff') format('woff'),
        url('../fonts/DIN-Black.ttf') format('truetype'),
        url('../fonts/DIN-Black.svg#DIN-Black') format('svg');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN';
    src: url('../fonts/DIN-Medium.eot');
    src: url('../fonts/DIN-Medium.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DIN-Medium.woff2') format('woff2'),
        url('../fonts/DIN-Medium.woff') format('woff'),
        url('../fonts/DIN-Medium.ttf') format('truetype'),
        url('../fonts/DIN-Medium.svg#DIN-Medium') format('svg');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DIN';
    src: url('../fonts/DIN.eot');
    src: url('../fonts/DIN.eot?#iefix') format('embedded-opentype'),
        url('../fonts/DIN.woff2') format('woff2'),
        url('../fonts/DIN.woff') format('woff'),
        url('../fonts/DIN.ttf') format('truetype'),
        url('../fonts/DIN.svg#DIN') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}



//FONT

@mixin font-regular{
  font-family: 'DIN';
    font-weight: normal;
    font-style: normal;
}
@mixin font-medium{
  font-family: 'DIN', sans-serif;
  font-weight: 500;
}
@mixin font-bold{
  font-family: 'DIN', sans-serif;
  font-weight: 700;
}
@mixin font-black{
  font-family: 'DIN', sans-serif;
  font-weight: 900;
}
