html{
  overflow-x: hidden;
  overflow-y: scroll;
  &.overflow{
    overflow: hidden;
  }
  background-color: $white;
  color: $dark;
  &::-webkit-scrollbar { width: 0 !important };
  overflow: -moz-scrollbars-none;
  -ms-overflow-style: none;
  min-height: -webkit-fill-available;
}

body {
  &.overflow{
    overflow: hidden;
  }
  background-color: $white;
  color: $dark;
  font-size: $font-size;
  line-height: 1.65em;
  transition: color 0.6s, background-color 0.6s;
  @include break(mobile){
    font-size: 18px;
    overflow-x: hidden;
    overflow-y: scroll;
  }
  @include font-regular;
  font-style: normal;
  min-height: 100vh;
  min-height: -webkit-fill-available;
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  z-index: 9999;
  z-index: 9999999999;
  background-color: $green;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;

  .svg_wrap {
    position: relative;
    width: 70%;
    .anime_svg {
      position: absolute;
      top: 0;
      left: 0;
      path, line {
        // stroke-dasharray: 118%;
      }
    }
  }
}

.container {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1064px;
}
.container_big {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  max-width: 1440px;
}

.flex {
  display: flex;
  justify-content: space-between;
}

.mobile {
  display: none;
  @include break(mobile) {
    display: block;
  }
}
.tablet {
  display: none;
  @include break(tablet) {
    display: block;
  }
}
.desktop{
  @include break(mobile) {
    display: none;
  }
}

a {
  color: inherit;
  text-decoration: none;
}

h1, h2, h3, h4 {
  a {
    color: inherit;
    text-decoration: none;
  }
}
h1 {
  @include font-bold;
  font-size: 4em;
  line-height: 1.2em;
  @include break(tablet) {
    font-size: 2.22em
  }
}

h2 {
  @include font-bold;
  font-size: 2.6em;
  line-height: 1.2em;
  @include break(tablet) {
    font-size: 2em;
  }
}

h3 {
  @include font-bold;
  font-size: 2em;
  line-height: 1.2em;
  @include break(tablet) {
    font-size: 1.77em;
  }
}

h4 {
  @include font-bold;
  font-size: 1.4em;
  line-height: 1.2em;
}

h6 {
  @include font-medium;
  font-size: 1em;
  line-height: 1.2em;
  margin: 0;
  padding: 0;
}

.play {
  width: 122px;
  height: 122px;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  cursor: pointer;
  transition: all 0.5s;
  .bg {
    width: 100%;
    height: 100%;
    background-color: $ocre;
    border-radius: 50%;
    transition: all 0.4s;
    z-index: 1;
  }
  span {
    pointer-events: none;
    font-size: 16px;
    text-transform: uppercase;
    color: $white;
    @include absolute-center(center);
    opacity: 0;
    transform: translate(-50%, -50%) scale(1.15);
    transition: all 0.1s;
    z-index: 2;
  }
  svg {
    margin-left: 5px;
    pointer-events: none;
    @include absolute-center(center);
    transition: all 0.3s;
    z-index: 2;
  }

  &:hover {
    .bg {
      transform: scale(1.25);
    }
    span {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
      transition: all 0.4s 0.1s;
    }
    svg {
      opacity: 0;
    }
  }
}


.btn {
  width: 170px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: $ocre;
  border-radius: 4px;
  color: $white;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
  border: 1px solid $ocre;
  @include font-bold;
  transition: all 0.4s;
  &:hover {
    color: $ocre;
    background-color: $white;
  }
}


.round {
  width: 160px;
  height: 160px;
  transform-origin: 50% 50%;
  transform-style: preserve-3d;
  cursor: pointer;
  transition: all 0.5s;
  @include break(tablet) {
    width: 140px;
    height: 140px;
  }
  .bg {
    width: 100%;
    height: 100%;
    background-color: $ocre;
    border-radius: 50%;
    transition: all 0.4s;
    z-index: 1;
  }
  span {
    pointer-events: none;
    font-size: 16px;
    text-transform: uppercase;
    color: $white;
    width: 100%;
    text-align: center;
    @include absolute-center(center);
    transform: translate(-50%, -50%);
    z-index: 2;
    line-height: 1.2em;
    @include font-bold;
    @include break(tablet) {
      font-size: 14px;
    }
    svg {
      margin-top: -10px;
      display: block;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 15px;
    }
  }

  &:hover {
    .bg {
      transform: scale(1.25);
    }
  }

}

.share_popup {
  display: none;
  z-index: 99999;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  color: $dark;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba($dark, 0.3);
  }
  .popup_container {
    text-align: center;
    padding: 60px 80px;
    @include absolute-center(center);
    background-color: #FAFAFA;
    width: 550px;
    backdrop-filter: blur(34px);
    border-radius: 28px;
    @include break(tablet) {
      width: 90%;
      padding: 32px;
    }

    .share_list {
      width: 100%;
      display: flex;
      justify-content: space-evenly;
      padding-top: 40px;
      padding-bottom: 20px;

      a {
        svg {
          overflow: visible;
          circle {
            transform-origin: center;
            transition: all 0.3s;
          }
        }
        &:hover circle {
          transform: scale(1.15);
        }
      }

    }

    .close {
      position: absolute;
      top: 20px;
      right: 40px;
      cursor: pointer;
    }

  }
}

body.theme_green {
  background-color: $green;
  color: $white;
  .sec2 {
    transition: background-position-y 0s!important;
    background-position-y: -280%!important;
  }
  header.small {
    border-bottom: none;
  }
}

body.theme_dark {
  background-color: $dark;
  color: $white;
  header.small {
    border-bottom: none;
  }
}

// COOKIES
.cky-notice .cky-title {
  font-family: "DIN", sans-serif!important;
}

.cky-consent-container .cky-consent-bar {
  border: 1px solid #6b7153!important;
  box-shadow: none!important;
}
